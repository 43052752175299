import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/Logo-Dev-Portfolio.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/icons8-github2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer" style={{ height: "150px" }}>
      <Container>
        <Row className="align-items-center">
          {/*<MailchimpForm /> */}

          <Col
            size={12}
            sm={6}
            style={{ height: "150px", display: "flex", alignItems: "end" }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ borderRadius: "50%", width: "150px", height: "auto" }}
            />
          </Col>
          <Col
            size={12}
            sm={6}
            className="text-center text-sm-end"
            style={{ height: "150px", paddingTop: "30px" }}
          >
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/luca-liam-spie%C3%9Fl-sch%C3%A4fer-3b0751224/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon1} alt="Icon" />
              </a>
              <a
                href="https://github.com/LiamSchaefer"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon2} alt="Icon" style={{ width: "70%" }} />
              </a>
              <a
                href="https://www.instagram.com/luca_liam.photography/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>
              {`Copyright Luca Schäfer ${new Date().getFullYear()}. All Rights Reserved`}
              <br></br>Development: Luca Schäfer
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

import meter1 from "../assets/img/logo-javascript.svg";
import meter2 from "../assets/img/css-3.svg";
import meter3 from "../assets/img/react-2.svg";
import meter4 from "../assets/img/java-14.svg";
import meter5 from "../assets/img/html-1.svg";
import meter6 from "../assets/img/nodejs-icon.svg";
import meter7 from "../assets/img/c--4.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/skillsfooter2.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                Here is a brief overview of some of the programming languages{" "}
                <br></br>I am familiar with and actively use in my projects or
                at work.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img
                    src={meter3}
                    alt="Image"
                    style={{ paddingBottom: "5px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>React</h5>
                </div>
                <div className="item">
                  <img
                    src={meter1}
                    class="slide-img"
                    alt="Image"
                    style={{ paddingBottom: "18px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>JavaScript</h5>
                </div>
                <div className="item">
                  <img
                    src={meter5}
                    alt="Image"
                    style={{ paddingBottom: "5px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>HTML</h5>
                </div>
                <div className="item">
                  <img
                    src={meter2}
                    alt="Image"
                    style={{ paddingBottom: "5px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>CSS</h5>
                </div>
                <div className="item">
                  <img
                    src={meter6}
                    alt="Image"
                    style={{ paddingBottom: "5px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>Node.JS</h5>
                </div>
                <div className="item">
                  <img
                    src={meter4}
                    alt="Image"
                    style={{ width: "32%", paddingBottom: "5px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>Java</h5>
                </div>
                <div className="item">
                  <img
                    src={meter7}
                    alt="Image"
                    style={{ paddingBottom: "5px" }}
                  />
                  <h5 style={{ marginTop: "20px" }}>C#</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};

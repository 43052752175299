import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, projectUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <a
          href={projectUrl}
          style={{ textDecoration: "none", color: "white" }}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={imgUrl}
            style={{ aspectRatio: "15/11", objectFit: "cover" }}
            alt="project cards"
          />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </a>
      </div>
    </Col>
  );
};
